/* Spinner.module.css */
.spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .loader {
    opacity: 1;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #6f15ff;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  