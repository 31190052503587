
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800&display=swap");
:root {
  --main: #210330;
  --white: #ffffff;
  --yellow: #ffbf09;
  --inputcolor: #f4f5fc;
  --fontcolor: #a8b6d2;
  --fontcolor2: #5580d5;
  --fontcolor3: #4b49b9;
  --fontgray: #444444;
  --label: #a8b6d2;
  --black: rgba(0, 0, 0, 0.3);
  --darkblack: rgba(0, 0, 0, 1);
  --purpletext: #4b49b9;
  --grid_width: 230px;
  --grid_height: 127px;
  --Hot_grid_width: 475px;
  --game_grid_width: 110px;
  --game_grid_height: 110px;
  --Canvas_grid_height:100%;
  --Canvas_grid_width:100%;
  --Left-Padding:76px;
  --Top-Padding:76px;
}
/* Game Grid Responsive  */
@media (min-width: 300px) and (max-width: 500px) {
  :root {
    --grid_width: 180px;
    --grid_height: 99px;
    --Hot_grid_width:372px;
  }
}

@media (min-width: 500px) and (max-width: 720px) {
  :root {
    
    --grid_width: 216px;
    --grid_height: 119px;
    --Hot_grid_width:444px;
  }
}

@media (min-width: 770px) {
  :root {
    --grid_width: 230px;
    --grid_height: 127px;
    --Hot_grid_width: 475px;
  }
}


/* Commen padding  */

@media (min-width: 770px) {
  :root {
    --Left-Padding:76px;
    --Top-Padding:76px;
  }
}

@media (max-width: 770px) {
  :root {
    --Left-Padding:10px;
    /* --Top-Padding:130px; */
  }
}

/* Ads start */
#home_300x250_1 {
  display: flex;
  grid-column: 1/3;
  grid-row: 3/6;
}

#home_300x250_2 {
  display: flex;
  grid-column: 1/3;
  grid-row: 8/10;
}
.ad-728X90-bottom {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.Ad_300x100{
  
  padding: 10px 10px;
}

.ad-300x600,
.ad-160x600,
.ad-728X90,
.ad-300X250,
.ad-300X250-2,
.Ad_300x100
{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.ad-300x600 {
  display: flex;
  grid-column: 1/3;
  grid-row: 2/6;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.inline-rectangle,
.wide-skyscraper,
.Leaderboard,
.MediumBanner,
.Horizon_auto
 {
  text-align: center;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  background-color: #300842;
}

.MediumBanner {
  width: 300px;
  height: 250px;
}

.inline-rectangle {
  width: 160px;
  height: 600px;
}

.wide-skyscraper {
  width: 160px;
  height: 600px;
}

.Leaderboard {
  width: 728px;
  height: 90px;
}

/* Responsive for Grid Sizes */
@media (max-width: 600px) {
  /* :root {
    --game_grid_width: 10px;
  --game_grid_height: 10px;
  } */
}
@media (min-width: 1000px) and (max-width: 1340px) {
  /* :root {
    --game_grid_width: 70px;
    --game_grid_height: 70px;
  } */
}

@media (max-width: 1620px) {
  :root {
    --game_grid_width: 80px;
    --game_grid_height: 80px;
  }
}

@media (min-width: 1620px) and (max-width: 1900px) {
  :root {
    --game_grid_width: 90px;
    --game_grid_height: 90px;
  }
}


/* 404 */

p>a{
color: var(--fontcolor3);
font-size: 20px;
font-weight: 800;
}

.contant a{
  color: var(--fontcolor3);
  text-decoration: none;
  }

 a:hover{
    color: #4400ff;
    text-decoration: none;
    }

.contant > h3 >a{
  color: var(--fontcolor3);
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  }

.contant > h3{
  display:inline !important;
  }
.container-404{
  display: flex;
  
  flex-direction: column;
  align-items: center;
  margin: 17px 0px; 
  min-height: 400px;
           }

.img-404{
  max-height: 350px;
  max-width: 350px;

}

.container-404 > .contant > h1 {
color: var(--white);
  font-size: 50px;
  padding-top: 0px;
  padding-bottom: 0px;
  
}

.container-404 > .contant > p {
  color: var(--white);
    font-size: 35px;
    padding-top: 25px;
  }

  .btn-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    width: 300px;
    height: 50px;
    background: rgb(0,245,255);
    background: linear-gradient(180deg, rgb(0, 183, 255) 20%, rgba(0,164,255,1) 100%);
    
  }
  .btn-404 > p {
    
    font-size: 25px;
    font-weight: 800;
    color: var(--white);
  }
  .grid-404{
    margin-top: 20px;
    width: 100%;
  }
 .txt-none{
  text-decoration: none;
  margin-bottom: 20px;
  }

  .btn-404 :hover{
    cursor: pointer;
    -webkit-transition: all 100ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 100ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 100ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 100ms ease-in;
    transform: scale(1.1);
  }
/* Snow FX */

body::-webkit-scrollbar {
  width:20px;
}
body::-webkit-scrollbar-track {
  background-color: #13011c;
  border-radius: 20px;
  
  width: 50px;
}
body::-webkit-scrollbar-thumb {
  background-color: #7f4b96;
  border-radius: 20px;
  border: 4px solid transparent;
  background-clip: content-box;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

.sf-snow-flake {
  position: fixed;
  top: -20px;
  z-index: 99999;
  }
  .sf-snow-anim {
  top: 110%;
  }

/* Sticky ADs */
.wgStickyBottom {
  min-height: unset !important;
}

/* Sticky End */
#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.app_cont {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
}
.app_cont_game {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
.version {
  padding: 20px 50px 0px 60px;
  width: 100%;
  display: grid;
  position: relative;
  justify-content: end;
}

.version_txt {
  color: var(--white2);
  padding-right: 15px;
  opacity: 0.8;
}

body {
  min-height: 100vh;
  margin: 0;
  /* position: relative; */
  margin: 0;
  padding: 0;
  background-color: var(--main);
  overflow-y: scroll;
  overflow-x: hidden !important;
  font-size: 14px;
  font-family: "Nunito", sans-serif !important;
  /* padding-bottom: 80px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Cookie consent */
.text_con {
  flex: unset !important;
}

/* Contack form */
.contact_form {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 20px 0px 20px 0px;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
}

.contact_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 850px;
  height: 550px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -webkit-box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -moz-box-shadow: 4px 5px 19px 11px rgba(40, 5, 100, 0.75);
  flex-wrap: wrap;
}
.contact_container_temp {
  display: flex;
  justify-content: center;
  width: 850px;
  height: 450px;
  background-color: white;
  border-radius: 20px;
  box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -webkit-box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -moz-box-shadow: 4px 5px 19px 11px rgba(40, 5, 100, 0.75);
  align-items: center;
  flex-direction: column;
}

.contact_logo_2_temp {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 160px;
}
.contact_part_1 {
  display: flex;
  flex-direction: column;
}
.contact_part_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.brand_info {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
}

.brand_info a {
  text-decoration: none;
  font-weight: 800;
}

.message-box-text {
  position: absolute;
  resize: none;
  width: 360px;
  height: 160px;
  border-radius: 20px;
  outline: none;
  border: none;
  font-size: 20px;
  color: black;
  padding: 15px;
  background-color: var(--inputcolor);
  font-weight: 700;
  font-family: "Nunito";
}

.massage_label {
  border: none;
  margin-top: -13px;
  position: absolute;
  transform: translatey(-50%);
  font-size: 13px;
  font-weight: 800;
  color: var(--label);
}
.submit_box {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 180px;
  width: 360px;
  height: 65px;
  position: relative;
}
.submit_btn {
  font-size: 20px;
  font-weight: 700;
  color: white;
  background-color: #817ffd;
  width: 220px;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 25px;
  font-family: "Nunito", sans-serif;
  box-shadow: 6px 6px 6px 0px rgb(202 201 255);
  -webkit-box-shadow: 6px 6px 6px 0px rgb(202 201 255);
  -moz-box-shadow: 6px 6px 6px 0px rgba(202, 201, 255, 1);
}

.title-text {
  margin-top: 20px;
  font-size: 30px;
  font-weight: 800;
  color: #4b49b9;
}

.contact_text {
  padding-left: 16px;
  position: absolute;
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 700;
  font-family: "Nunito";
  color: black;
  background-color: var(--inputcolor);
}
.contact_logo_2 {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 160px;
}
.contact_logo_1 {
  display: none;
  align-content: center;
  justify-content: center;
  height: 160px;
}

.contact_label {
  position: absolute;
  top: -15px;
  transform: translatey(-50%);
  font-size: 13px;
  font-weight: 800;
  color: var(--label);
}

.contact_input_box {
  width: 360px;
  height: 65px;
  margin: 35px 0;
  position: relative;
}

/* Privacy page */
.privacy-form {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0px 20px 0px;
}
.privacy-container {
  display: flex;
  justify-content: flex-start;
  margin: 0px 20px 20px 20px;
  /* align-items: center; */
  padding: 20px 20px 20px 20px;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -webkit-box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -moz-box-shadow: 4px 5px 19px 11px rgba(40, 5, 100, 0.75);
  flex-direction: column;
}
.privacy-container > h2,
h1 {
  color: var(--fontcolor3);
  font-weight: 800;
  font-size: 30px;
  margin: 0px;
}
.privacy-container > h1 {
  text-align: center;
}

.privacy-container > p {
  color: var(--fontgray);
  font-weight: 700;
  font-size: 18px;
  margin: 10px;
}

.privacy-container > li {
  color: var(--fontgray);
  font-weight: 700;
  font-size: 18px;
  margin: 0 20 10 30;
}

.privacy-logo {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 160px;
}

.partners-logo {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 20px 0px;
  /* width: 100%;
  height: 160px; */
}
/* End */

.p_center {
  color: var(--fontgray);
  font-weight: 700;
  font-size: 18px;
  margin: 10px;
  text-align: center;
}
/* Free Game */

.free_container {
  display: flex;
  justify-content: flex-start;
  margin: 60px 20px 20px 76px;
  /* align-items: center; */
  padding: 20px 20px 20px 20px;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;
  box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -webkit-box-shadow: 4px 5px 19px 11px rgb(40 5 100 / 75%);
  -moz-box-shadow: 4px 5px 19px 11px rgba(40, 5, 100, 0.75);
  flex-direction: column;
}

.free_game_con {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}
.free_games {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 47%;
  /* border-color: var(--fontcolor2);
  border-style: dashed; */
  margin: 20px;
  border-radius: 10px;
}

.free_container > h2 {
  text-align: center;
  color: var(--fontcolor3);
  font-weight: 800;
  font-size: 30px;
  margin: 0px;
}
.free_container > h1 {
  text-align: center;
}

.free_container > p {
  text-align: center;
  color: var(--fontgray);
  font-weight: 700;
  font-size: 18px;
  margin: 10px;
}

.free_con_1 {
  display: flex;
  width: 95%;
  margin: 2% 0% 0% 0%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.free_con_2 {
  text-align: center;
  width: 95%;
}
.free_thumb {
  min-width: 150px;
  max-width: 150px;
}
.free_img {
  width: 100%;
  border-radius: 10px;
}

.game_links {
  margin: 0px 0px 0px 20px;
  width: 100%;
}
.game_links h2 {
  width: 100%;
  margin: 0px;
  font-size: 23px;
  font-weight: 800;
  color: var(--purpletext);
}
.game_links a {
  width: 100%;
  margin: 0px 0px 0px 10px;
  font-size: 15px;
  font-weight: 700;
  color: #e12f79;
  text-decoration: none;
  word-wrap: break-word;
}

.game_links .dis-liks {
  padding-top: 5px;
}

.free-dis {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
  height: 150px;
  margin: 0px;
}

.dis-text {
  text-align: left;
  font-size: 16px;
  font-weight: 700;
  padding: 6px 10px 0px 10px;
  color: #4d4d4d;
}

.dis-head {
  font-size: 18px;
  font-weight: 700;
  padding: 6px 10px 0px 0px;
  color: var(--purpletext);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* 
::-webkit-scrollbar {
  display: block;
} */

/*Consernt banner*/
* {
  box-sizing: border-box;
}
/* PageTitle */
.page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  grid-column: auto/span 2;
  color: white;
  font-size: 35px;
  font-weight: 800;
  box-shadow: none;
}


.thumb-2x1 {
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}

.thumb-2x2 {
  height: 320px;
  grid-column: auto/span 2;
  grid-row: auto/span 2;
}

.thumb-2x2 > .new-badge {
  display: flex;
  position: absolute;
  width: 110px;
  height: 110px;
  transform: translate(-8px, -8px);
  z-index: 3;
  background-repeat: no-repeat;
  background-size: cover;
}



.thumb-2x1 > .new-badge {
  display: flex;
  position: absolute;
  width: 55px;
  height: 55px;
  transform: translate(-4px, -4px);
  z-index: 3;
  background-repeat: no-repeat;
  background-size: cover;
}



.thumb-2x2:hover > .new-badge {
  display: none;
}
.thumb-2x1:hover > .new-badge {
  display: none;
}
.container {
  width: 100%;
  align-content: center;
  justify-content: right;
  margin-top: var(--Top-Padding);
  /* padding-left: 76px; */
 
}




.empty-thumb::before {
  height: 130px;
  content: "";
  display: block;
  grid-column-start: span 2;
}

.thumb-container {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.thumb-img {
  width: 100%;
  height: 100%;
  -webkit-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
}

.thumb-container:hover > .thumb-img {
  -webkit-transition: all 100ms ease-in;
  -webkit-transform: scale(1.2);
  -ms-transition: all 100ms ease-in;
  -ms-transform: scale(1.2);
  -moz-transition: all 100ms ease-in;
  -moz-transform: scale(1.2);
  transition: all 100ms ease-in;
  transform: scale(1.2);
}
.banner_image {
  width: 100%;
  height: 87%;
  background-size: cover;
  background-color: hotpink;
  overflow: hidden;
  background-repeat: no-repeat;
  -webkit-transition: all 200ms ease-in;
  -ms-transition: all 200ms ease-in;
  -moz-transition: all 200ms ease-in;
  transition: all 200ms ease-in;
}
.banner_title {
  width: 100%;
  height: 13%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: black;
  font-size: 20px;
  font-weight: 800;
  position: absolute;
  background-color: var(--white);
  bottom: 0;
}

.banner_flex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  cursor: pointer;
}

.title {
  display: flex;
  align-items: flex-end;
  position: absolute;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 105%;
  color: var(--white);
  opacity: 0;
  font-size: 14px;
  font-weight: 700;
  text-shadow: 0px 1px 1px #000000;
}


.thumb-2x1:hover > .thumb-container > .title {
  z-index: 2;
  opacity: 1;
  height: 95%;
  transition: height 0.1s;
}

.thumb-2x2:hover > .thumb-container > .title {
  z-index: 2;
  opacity: 1;
  height: 95%;
  transition: height 0.1s;
  font-size: 20px;
}



.thumb-2x1:hover {
  z-index: 3;
  -webkit-transition: all 100ms ease-in;
  -webkit-transform: scale(1);
  -ms-transition: all 100ms ease-in;
  -ms-transform: scale(1);
  -moz-transition: all 100ms ease-in;
  -moz-transform: scale(1);
  transition: all 100ms ease-in;
  transform: scale(1);
}

.thumb-2x2:hover {
  z-index: 3;
  -webkit-transition: all 100ms ease-in;
  -webkit-transform: scale(1.05);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.05);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.05);
  transition: all 200ms ease-in;
  transform: scale(1.05);
}




.thumb-2x1:hover > .thumb-container > .cover {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s;
}

.thumb-2x2:hover > .thumb-container > .cover {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s;
}


.thumb-canvas {
  grid-column: auto/span 6;
  grid-row: auto/span 4;
  z-index: 1;
  background-image: none !important;
  /* background-color: var(--white); */
  overflow: hidden;
  border-radius: 10px;
}

.canvas-containerr {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.game-iframe {
  width: 100%;
  height: 600px;
  background-color: hotpink;
}

.game-canvas {
  height: 100%;
}

.game-info {
  height: 50px;
  display: flex;
  flex-direction: row;
  background-color: var(--white);
  align-items: center;
  align-content: center;
  justify-content: space-between;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.game-title {
  user-select: none;
  padding-left: 13px;
  margin: 0;
  font-weight: 800;
  font-size: 20px;
}

.game-fullscreen {
  margin-right: 13px;
  display: flex;
  width: 30px;
  height: 30px;
}

/* Mobile canvas */

.mobile-container {
  flex-direction: column;
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  justify-content: space-between;
}

.mobile-view {
  display: none;
  margin-top: -17;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--main);
  z-index: 10;
  bottom: 0;
}

.mobile-black_screen {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.6;
  z-index: 12;
}

.mobile-menu {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 13;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.menu-container {
  display: flex;
  width: 280px;
  height: 300px;
  background-color: white;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.mhead {
  margin: 0;
  font-size: 32px;
  font-weight: 800;
  color: var(--fontcolor3);
}

.mhead2 {
  font-size: 18px;
  font-weight: 700;
  color: var(--fontgray);
}

.yes {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 200px;
  height: 45px;
  background-color: #fe5655;
  border-radius: 22px;
  cursor: pointer;
  z-index: 5;
}

.no {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 200px;
  height: 45px;
  background-color: #00a8ff;
  border-radius: 22px;
  cursor: pointer;
  z-index: 5;
}

.mText {
  color: var(--white);
  font-size: 28px;
  font-weight: 800;
}

.mobile-canvas {
  margin: 0;
  position: absolute;
  z-index: 10;
}

.mobile-info {
  opacity: 0.7;
  pointer-events: none;
  position: absolute;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  z-index: 11;
  /* bottom: 0; */
}

.mobile-fullscreen {
  pointer-events: visible;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 30px 0px 0px 0px;
  background-color: var(--white);
  cursor: pointer;
}

.mobile-exit {
  pointer-events: visible;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 0px 30px 30px 0px;
    background-color: var(--white);
    cursor: pointer;
}
/*Full screen canvas size issue*/
.canvas-containerr:fullscreen .game-canvas {
  height: 94%;
}

.canvas-containerr:fullscreen .game-info {
  height: 6%;
}

.canvas-containerr:fullscreen .game-info .game-title {
  font-size: 25px;
}

.canvas-containerr {
  display: flex;
  flex-direction: column;
  height: 96%;
}

.game-canvas {
  height: 100%;
}
/* All Cat page */

/* .tags_roots{
  margin-top: 17px;
} */
.tags_heading{
  display: flex;
  width: 100%;
  text-align: center;
  height: 130px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.cat_heading{
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.cat_heading h2{
  color: var(--yellow);
  font-weight: 800;
  font-size: 30px;
  margin: 0px;
}
.cat_heading h1{
  color: var(--yellow);
  font-weight: 800;
  font-size: 30px;
  margin: 0px;
}
 
/* Logo start */

#logo-container1 {
  display: flex;
  width: 100%;
  position: absolute;
  z-index: 5 !important;
  justify-content: space-around;
}

.logo-flex {
  display: flex;
  width: 1850px;
  /* height: 20px;*/
  position: relative;
  /*background-color: aqua;*/
}

.logo-thumb {
  display: flex;
  position: absolute;
  width: 280px;
  height: 130px;
  border-radius: 10px;
  background-color: var(--white);
  box-shadow: 0 0 10px rgb(0 0 0 / 60%);
}

.logo-btns {
  display: flex;
  flex-direction: column;
}

.logo_image:after {
  content: " ";
}
.logo_image {
  margin-left: 25px;
  content: " ";
}

.logo {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 242px;
  /*background-color:  var(--white);*/
  border-radius: 10px 0px 0px 10px;
}

.logo-btns {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 100%;
  margin-left: -5px;
}

.logo-home {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 60px;
  border-radius: 0px 10px 0px 0px;
}

.logo-down {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 65px;
  width: 60px;
  border-radius: 0px 0px 10px 0px;
}

.logo-down.up {
  animation: up_animation 0.5s ease-in-out;
  transform: rotate(180deg);
}

.logo-down.down {
  animation: down_animation 0.8s 0.5s ease-in-out infinite;
}

.logo-down.backtodown {
  transform: rotate(0deg);
  transition: transform 0.5s ease-in-out;
}
/* Bottom Dis */

/* .dis-container {
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
} */

.dis-container {
  margin-top: 17px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding-left: var(--Left-Padding);
}

.dis {
  width: 100%;
}

.videos {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.videos_iframe {
  border-radius: 10px;
}
.thumb-dis {
  padding: 20px 0px 20px 0px;
  margin-right: 10px;
  border-radius: 10px;
  display: flex;
  background-color: white;
  flex-direction: row;
  width: 100%;

}

.contant > h3{

  color: var(--fontcolor3);
  font-weight: 800;
  font-size: 20px;
  margin: 10px;
  margin-left: 0px;

}

.contant > p{

  margin: 0px;

}
.contant > ul > li{

  padding-bottom: 10px;

}

.contant > a {
  color: var(--fontcolor3) !important;
  text-decoration: none;
}

.contant {
  padding: 5px 20px 10px 20px;
  color: var(--fontcolor3);
  margin: 0;
  font-size: 25px;
  font-weight: 800;
}

.Sub_Heading{
  padding: 15px 20px 10px 0px;
  color: var(--fontcolor3);
  margin: 0;
  font-size: 25px;
  font-weight: 800;
}
.Sub_Heading2{
  padding: 15px 0px 5px 0px;
  color: var(--fontcolor3);
  margin: 0;
  font-size: 20px;
  font-weight: 800;
}
.P_tag {
  margin: 0px 0px 0px 10px !important;
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.Side_padding{
  margin-left: 20px;
}

.Side_padding2{
  margin-left: 15px;
}

.para {
  
  color: black;
  font-size: 18px;
  font-weight: 600;
}
.para > a {
  text-decoration: none;
}

.pathpara {
  padding-left: 5px;
  padding-right: 5px;
  color: black;
  font-size: 14px;
}
.pathpara:hover {
  text-decoration: underline var(--main);
  font-weight: 700;
  cursor: pointer;
  color: black;
}
.tags {
  margin-right: 5px;
  padding: 0px 15px;
  border: 2px solid var(--fontcolor);
  border-radius: 100px;
  color: var(--fontcolor);
  font-size: 12px;
  font-weight: bold;
}
.description > a {
  color: var(--fontcolor3) !important;
  text-decoration: none;
}

.description > p > a {
  color: var(--fontcolor3) !important;
  text-decoration: none;
}

.gamelist {
  padding: 0px 0px 0px 50px;
  margin: 0;
}
.gamelist > li {
  margin: 10px 0px 10px 0px;
}

/*Footer*/
.footer-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  /* position: absolute; */
  align-content: center;
  align-items: center;
  bottom: 0px;
}

.foot_div {
  display: flex;
  margin: 20px 0px 20px 0px;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  bottom: 20px;
}

.footer-container a {
  text-decoration: none;
}

.text {
  color: white;
}

.footer-text {
  color: white !important;
  margin-left: 30px;
}

/* .tags :hover{
  border: 2px solid var(--main);
  color: var(--main);
 cursor: pointer;
} */

/* Search */

.Search_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: var(--main);
  width: 900px;
  height: 100%;
  z-index: 20;
  transform: translateX(-100%);
}

.Search_div h2 {
  font-size: 26px;
  color: white;
  margin-left: 80px;
  width: 100%;
}

.Search_div.Sin {
  transform: translateX(0px);
  transition: transform 0.3s;
}

.Search_div.Sout {
  transform: translateX(-100%);
  transition: transform 0.3s;
}

.Search_container {
  height: 65px;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.Search_input_div {
  display: flex;
  justify-content: space-between;
  margin-left: 20;
  border-radius: 60px;
  flex-direction: row;
  height: 100%;
  background-color: white;
  width: 80%;
  overflow: hidden;
}

.Search_input {
  width: 90%;
  height: 100%;
}

.Search_Feild {
  outline: 0px;
  width: 100%;
  height: 100%;
  margin: 0px 0px 0px 20px;
  border: none;
  font-family: "Nunito", sans-serif;
  font-size: 25px;
  font-weight: 800;
}

input::placeholder {
  color: #b2a9b6;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
}

.Search_icon {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0px 20px 0px 20px;
  width: 10%;
  height: 100%;
}

.s_icon {
  width: 40px;
  height: 40px;
}

.c_icon {
  width: 40px;
  height: 40px;
}

.Search_close {
  display: flex;
  margin: 0 10 0 10;
  height: 100%;
  width: 65px;
  background-color: white;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.Recent_games,
.Search_game_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: row dense;
  justify-content: center;
  grid-gap: 20px;
  grid-column-start: span 2;
  overflow-y: auto;
  padding: 15px 0px 30px 0px;
}

.s_thumb {
  grid-column: auto/span 1;
  grid-row: auto/span 1;
  min-height: 150px;
  display: block;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 40%);
  transition: all 0.2s;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}

.s_title {
  display: flex;
  align-items: flex-end;
  position: absolute;
  align-content: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 105%;
  color: var(--white);
  opacity: 0;
  font-size: 14px;
  font-weight: 700;
  text-shadow: 0px 1px 1px #000000;
}

.s_thumb:hover > .s_title {
  z-index: 2;
  opacity: 1;
  height: 95%;
  transition: height 0.1s;
}

.s_cover {
  position: absolute;
  width: 100%;
  height: 101%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.1) 50%
  );
  opacity: 0;
}

.s_thumb:hover > .s_cover {
  z-index: 1;
  opacity: 1;
  transition: opacity 0.2s;
}

.s_thumb:hover {
  z-index: 3;
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.15);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.15);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.15);
  transition: all 200ms ease-in;
  transform: scale(1.15);
}

.s_thumb:hover > .thumb-img {
  -webkit-transition: all 200ms ease-in;
  -webkit-transform: scale(1.1);
  -ms-transition: all 200ms ease-in;
  -ms-transform: scale(1.1);
  -moz-transition: all 200ms ease-in;
  -moz-transform: scale(1.1);
  transition: all 200ms ease-in;
  transform: scale(1.1);
}

/* Cate set up */
/*menu set up*/
.menu {
  margin-left: 0px;
  margin-top: 120px;
  border-radius: 0px 0px 10px 10px;
  width: 280px;
  height: 0px;
  background-color: var(--white);
  position: absolute;
  z-index: 5;
  overflow-y: scroll;
  transform-origin: top center;
}
.menu::-webkit-scrollbar {
  display: none;
}

.menu.fade {
  height: 608px;
  transition: height 0.3s ease;
}

.menu.out {
  height: 0px;
  transition: height 0.3s ease;
}
.menu.out2 {
  height: 0px;
  transition: height 0s ease;
}

.list {
  display: flex;
  flex-direction: column;
}

.items {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  color: var(--darkblack) !important;
  font-size: 19px;
  font-weight: 900;
  width: 280x;
  height: 61px;
  padding-left: 40px;
  border-radius: 10px;
  text-align: center;
  border-bottom-width: 1px;
  align-items: center;
  text-decoration: none;
}

.menutext {
  padding-left: 10px;
}
.items:hover {
  background-color: #ffdbea;
}

.container {
  width: 100%;
  align-content: center;
  justify-content: center;
}

/*Page nav*/

.page_nav {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
}
.page_nav ul a {
  text-decoration: none !important;
}
.page_nav ul {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: #fff;
  list-style: none;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}
.spantext {
  color: #fff;
  list-style: none;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease;
}
.numb {
  text-align: center;
  list-style: none;
  height: 45px;
  width: 45px;
  margin: 0 5px;
  line-height: 45px;
  border-radius: 30%;
  background-color: var(--yellow);
}
.numb:hover {
  opacity: 0.5;
}
.first {
  margin: 0px 3px 0 -5px;
}
.last {
  margin: 0px -5px 0 3px;
}
.dots {
  font-size: 22px;
  cursor: default;
}
.btn {
  padding: 0 20px;
  border-radius: 50px;
}
.active {
  opacity: 0.5;
}
.numb :hover {
  opacity: 0.5;
}

.game-root:fullscreen {
  margin: 0;
  background-color: #50136c;
  width: 100%;
  height: 100%;
  font-family: "Nunito", sans-serif;
  overflow: scroll !important;
}
.game-root:-webkit-full-screen {
  margin: 0;
  background-color: #50136c;
  width: 100%;
  height: 100%;
  font-family: "Nunito", sans-serif;
  overflow: scroll !important;
}
.game-root:-moz-full-screen {
  margin: 0;
  background-color: #50136c;
  width: 100%;
  height: 100%;
  font-family: "Nunito", sans-serif;
  overflow: scroll !important;
}

.Navication_container {
  display: none;
  flex-direction: row;
  position: fixed;
  align-content: space-around;
  justify-content: space-around;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px 5px 0px 0px;
  z-index: 10;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  /* background-color: rgb(7, 88, 182); */
  z-index: 2;
}

.home {
  width: 35px;
  height: 35px;
}

.home_text {
  color: rgb(93, 93, 93);
  font-size: 13px;
  font-weight: 700;
}
/* Black screen */

#black_screen {
  display: block;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 5 !important;
  opacity: 0.5;
}

/*Down animation*/

@-moz-keyframes down_animation {
  0% {
    -moz-transform: translateY(0px);
  }

  50% {
    -moz-transform: translateY(5px);
  }

  100% {
    -moz-transform: translateY(0px);
  }
}

@-webkit-keyframes down_animation {
  0% {
    -webkit-transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(5px);
  }

  100% {
    -webkit-transform: translateY(0px);
  }
}

@keyframes down_animation {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(5px);
  }

  100% {
    transform: translateY(0px);
  }
}

/*Up Animation*/

@-moz-keyframes up_animation {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(180deg);
  }
}

@-webkit-keyframes up_animation {
  0% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes up_animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}


@media only screen and (min-width: 300px) {
/* All Cat page */
.tags_heading{
  height: 100px;
}
.cat_heading h2{
  font-size: 25px;
}
  /* Contact Form */
  .contact_container {
    padding: 20px 0px 20px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: auto;
  }
  .contact_container_temp {
    padding: 20px 0px 20px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: auto;
  }
  .brand_info {
    padding: 30px;
  }

  .title-text {
    margin-top: 10px;
    font-size: 20px;
  }
  .contact_input_box,
  .submit_box,
  .message-box-text {
    width: 270px;
  }
  .message-box-text,
  .contact_text {
    font-size: 15px;
  }

  .contact_logo_1 {
    display: flex;
  }
  .contact_logo_2 {
    display: none;
  }
  /* Footer */
  .foot_div {
    flex-direction: column;
  }

  /* Free Game */
  .free_game_con {
    flex-direction: column;
  }
  .game_links h2 {
    margin: 20px 0px 0px 0px;
  }
  .free_games {
    width: 100%;
  }
  .free_thumb {
    min-width: 150px;
    max-width: 150px;
  }
  .free_con_1 {
    flex-direction: column;
  }

  .videos {
    display: none !important;
  }

  /*Catge*/
  .page-title {
    font-size: 20px;
  }
  /*For Game*/

  .game-fullscreen {
    width: 25px;
    height: 25px;
  }

  .game-title {
    font-size: 16px;
  }

  .footer-container {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .tags-list {
    display: none;
  }
}

@media only screen and (min-width: 360px) {
     /* 404 */
.container-404{
  padding-top:120px ;
           }
.container-404 > .contant > h1 {
  font-size: 25px;
}
.container-404 > .contant > p {
    font-size: 15px;
  }

  .btn-404 {
    margin-top: 20px;
    border-radius: 50px;
    width: 300px;
    height: 50px;
  }
  .btn-404 > p {
    font-size: 25px;
  }
 
    /* All Cat page */
    .tags_heading{
      height: 130px;
    }
    .cat_heading h2{
      font-size: 30px;
    }
}

@media only screen and (min-width: 477px) {
  /* Contact Form */
  .contact_container {
    padding: 20px 0px 20px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: auto;
  }
  .contact_container_temp {
    padding: 20px 0px 20px 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 450px;
    height: auto;
  }
  .contact_input_box,
  .submit_box,
  .message-box-text {
    width: 360px;
  }

  .title-text {
    margin-top: 20px;
    font-size: 30px;
  }
  .message-box-text,
  .contact_text {
    font-size: 20px;
  }
  /* Footer */
  .foot_div {
    flex-direction: row;
  }

  /*Ads set up*/
  .MediumBanner {
    overflow: hidden;
    width: 300px;
    min-height: 250px;
    max-height: 250px;
  }

  
  .ad-300x600 {
    display: none;
    grid-column: 1/3;
    grid-row: 7/11;
  }

  .thumb-canvas {
    height: 340px;
    grid-column: auto/span 4;
    grid-row: auto/span 3;
    border-radius: 10px;
  }

  /*For Home*/
  
  .logo-flex {
    position: fixed;
    width: 460px;
  }
}

@media only screen and (min-width: 597px) {
     /* 404 */
.container-404{
  padding-top:120px ;
           }
.container-404 > .contant > h1 {
  font-size: 35px;
}
.container-404 > .contant > p {
    font-size: 25px;
  }
  /*Ads set up*/

  

  .thumb-canvas {
    height: 340px;
    grid-column: auto/span 5;
    grid-row: auto/span 3;
    border-radius: 10px;
  }

  .game-title {
    font-size: 20px;
  }

  .Search_Feild {
    font-size: 20px;
  }

  /*For Home*/

  .logo-flex {
    position: fixed;
    width: 580px;
  }
}

@media only screen and (min-width: 717px) {
  /* Free Game */
  .game_links h2 {
    margin: 0px;
  }
  .free_con_1 {
    flex-direction: row;
  }
  .free_thumb {
    min-width: 150px;
    max-width: 150px;
  }
  /* Ad setup */

  #home_300x250_2 {
    display: flex;
    grid-column: 1/4;
    grid-row: 12/15;
  }

  /* video */
  .videos {
    display: flex !important ;
  }
  .thumb-dis {
    display: flex;
    flex-direction: column;
  }
 
  
  /*For Game*/

  /*For Home*/

  .logo-flex {
    position: fixed;
    width: 700px;
  }
}

@media only screen and (min-width: 747px) {
   /* 404 */
.container-404{
  padding-top:120px ;
           }
.container-404 > .contant > h1 {
  font-size: 40px;
}
.container-404 > .contant > p {
    font-size: 30px;
  }
  /*Ads set up*/
  #home_300x250_1 {
    display: flex;
    grid-column: 1/3;
    grid-row: 4/6;
  }
  #home_300x250_2 {
    display: flex;
    grid-column: 1/3;
    grid-row: 12/14;
  }
  .tags-list {
    display: block;
  }
  .ad-300x600 {
    display: none;
    /* display: flex; */
    grid-column: 1/3;
    grid-row: 7/11;
  }
  
  /*Categ*/

  .page-title {
    font-size: 30px;
  }

  /*For Game Screen*/

  .game-fullscreen {
    width: 30px;
    height: 30px;
  }

  .thumb-canvas {
    height: 430px !important;
    grid-column: auto/span 4;
    grid-row: auto/span 3;
  }

  .thumb {
    min-height: 120px;
    min-width: 120px;
    border-radius: 10px;
  }

  
  .empty-thumb::before {
    height: 120px;
  }

  .logo-game-screen {
    position: absolute !important;
  }

  /*For Home*/
  .Search_input_div {
    width: 80%;
  }

  .Search_container,
  .Search_close {
    height: 65px;
  }

  .Search_close {
    width: 65px;
  }

  .s_icon {
    width: 40px;
    height: 40px;
  }

  .c_icon {
    width: 40px;
    height: 40px;
  }

  .Search_Feild {
    font-size: 25px;
  }

  .Search_div h2 {
    font-size: 26px;
  }

  .Recent_games,
  .Search_game_container {
    grid-template-columns: repeat(auto-fill, 130px);
  }

  .s_thumb {
    min-height: 130px;
  }

  .banner_title {
    font-size: 20px;
  }
  .thumb-2x2 {
    height: 250px;
  }

 
  .thumb-2x1 > .new-badge {
    width: 55px;
    height: 55px;
    transform: translate(-4px, -4px);
  }
  .thumb-2x2 > .new-badge {
    width: 80px;
    height: 80px;
    transform: translate(-6px, -6px);
    z-index: 3;
  }
  .thumb-banner {
    height: 280px;
    grid-column: 1/ 4;
    grid-row: 2/4;
  }
  .logo-flex {
    position: fixed;
    width: 730px;
  }

  .logo-thumb {
    transform: scale(1);
  }

  .footer-container {
    flex-direction: row;
    padding: 20px 50px 0 50px;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 897px) {
  /* Contact Form */
  .contact_container {
    padding: 0px;
    flex-direction: row;
    width: 850px;
    height: 550px;
    align-items: unset;
    justify-content: space-around;
  }
  .brand_info {
    padding: 10px;
  }
  .contact_container_temp {
    width: 800px;
    height: 550px;
  }
  .contact_logo_1 {
    display: none;
  }
  .contact_logo_2 {
    display: flex;
  }
  /*Ads set up*/

 
  .ad-300x600 {
    width: 100%;
    grid-column: 1/3;
    grid-row: 9/13;
  }

  /*For Game*/

  .thumb-canvas {
    height: 580px !important;
    grid-column: auto/span 5;
    grid-row: auto/span 4;
  }

  .logo-flex {
    position: absolute;
    width: 880px;
  }

  .footer-container {
    padding: 0px 100px 0px 100px;
  }
}

@media only screen and (min-width: 1047px) {
  /*Ads set up*/
  #home_300x250_1 {
    display: flex;
    grid-column: 1/3;
    grid-row: 3/5;
  }

 
  .ad-300x600 {
    grid-column: 1/3;
    grid-row: 9/13;
  }

  .thumb-canvas {
    height: 580px !important;
    grid-column: auto/span 6;
    grid-row: auto/span 4;
  }

  /*For Home*/
  .Search_div {
    width: 900px;
  }

  .thumb-banner {
    grid-column: auto/span 3;
    grid-row: auto/span 2;
  }
  

  .logo-flex {
    position: absolute;
    width: 1030px;
  }
}

@media only screen and (min-width: 1197px) {
  
  /* Video */

  .thumb-dis {
    flex-direction: row;
  }

  /*Ads set up*/
  #home_300x250_2 {
    display: none;
  }

  

  /*For Game*/
  .thumb-canvas {
    grid-column: 3/9;
    grid-row: auto/span 4;
  }

  /*For Home*/
  .logo-flex {
    position: fixed;
    width: 1180px;
  }
}

@media only screen and (min-width: 1347px) {
  /* 404 */
.container-404{
  padding-top:0px ;
           }
.container-404 > .contant > h1 {
  font-size: 40px;
}
.container-404 > .contant > p {
    font-size: 30px;
  }
  
  /* free game */
  .free_game_con {
    flex-direction: row;
  }
  .free_games {
    width: 47%;
  }
  /* Ads */
  .ad-300x600 {
    grid-column: 1/3;
    grid-row: 2/6;
  }



  /*For game*/

  
  .thumb-canvas {
    grid-column: auto/span 6;
    grid-row: auto/span 4;
  }

  .logo-game-screen {
    position: fixed !important;
  }

  /*For Home*/
  .logo-flex {
    position: fixed;
    width: 1330px;
  }
}

@media only screen and (min-width: 1497px) {
  /*Ads set up*/

  .ad-300x600 {
    display: flex;
    grid-column: 1/3;
    grid-row: 2/6;
  }

  /*For Game*/

  
  .thumb-canvas {
    height: 730px !important;
    grid-column: auto/span 8;
    grid-row: auto/span 5;
  }

  /*For Home*/
  .logo-flex {
    position: fixed;
    width: 1480px;
  }
}

@media only screen and (min-width: 1645px) {
  .logo-flex {
    position: fixed;
    width: 1630px;
  }
  /*Game*/

  .ad-300x600 {
    display: flex;
    grid-column: 1/3;
    grid-row: 2/6;
  }

}

@media only screen and (min-width: 1797px) {
  .logo-flex {
    position: fixed;
    width: 1780px;
  }

}
@media only screen and (min-width: 1947px) {
  .logo-flex {
    position: fixed;
    width: 1930px;
  }
@media only screen and (min-width: 2097px) {
  .logo-flex {
    position: fixed;
    width: 2080px;
  }
}
@media only screen and (min-width: 2247px) {
  .logo-flex {
    position: fixed;
    width: 2230px;
  }
}
@media only screen and (min-width: 2397px) {
  .logo-flex {
    position: fixed;
    width: 2380px;
  }
}
@media only screen and (min-width: 2547px) {
  .logo-flex {
    position: fixed;
    width: 2530px;
  }
}
}
